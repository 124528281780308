.preloader {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #fff;
  z-index: 99999;
}

.preloader img {
  -webkit-animation: floating 1s cubic-bezier(0.4, -0.35, 0.01, 1.08) infinite
    alternate;
  -moz-animation: floating 1s cubic-bezier(0.4, -0.35, 0.01, 1.08) infinite
    alternate;
  -ms-animation: floating 1s cubic-bezier(0.4, -0.35, 0.01, 1.08) infinite
    alternate;
  -o-animation: floating 1s cubic-bezier(0.4, -0.35, 0.01, 1.08) infinite
    alternate;
  animation: floating 1s cubic-bezier(0.4, -0.35, 0.01, 1.08) infinite alternate;
}

@-webkit-keyframes floating {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes floating {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-ms-keyframes floating {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-o-keyframes floating {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes floating {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
